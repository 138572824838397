import React, { useState, useRef, useEffect } from "react"
import BlockWrapper from "../technical/BlockWrapper"

import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { CaretDown24 } from "@carbon/icons-react"

const FaqBlock = ({ block }) => {
  const [activeFaqState, setActiveFaqState] = useState(null)
  const refContainers = useRef([])

  const refContainersCollection = (el) => {
    if (el && !refContainers.current.includes(el)) {
      refContainers.current.push(el)
    }
  }

  // Workaround: Verhindert falsche Start-Höhe des Contents, weil Bild zu lange zum Laden braucht. => Refactoring
  useEffect(() => {
    setTimeout(() => {
      setActiveFaqState(0)
    }, 1000)
  }, [])

  const toggleFaq = (index) => {
    const newActiveFaqState = activeFaqState !== index ? index : null
    setActiveFaqState(newActiveFaqState)
  }

  return (
    <BlockWrapper block={block}>
      <div className="mt-8">
        {block.faq_items?.length > 0 &&
          block.faq_items.map((item, index) => (
            <div key={index}>
              <button
                className={`w-full py-2 font-bold border-2 text-left border-gray-200 px-grid hover:bg-gray-100 ${
                  activeFaqState === index ? " bg-gray-100" : ""
                } ${index > 0 ? "-mt-0.5" : ""}`}
                onClick={() => toggleFaq(index)}
              >
                <CaretDown24
                  className={`inline-block -mt-1 mr-2 transition-transform duration-500 transform ${
                    activeFaqState === index ? " rotate-180" : ""
                  }`}
                />
                {item.title}
              </button>
              <div
                className={`overflow-hidden transition-all duration-500 ${
                  activeFaqState === index ? "border-2 border-gray-200" : ""
                }`}
                ref={refContainersCollection}
                style={{
                  marginTop: activeFaqState === index ? "-2px" : "0px",
                  height: `${
                    activeFaqState === index
                      ? `${refContainers?.current[index]?.scrollHeight}px`
                      : "0px"
                  }`,
                }}
              >
                <Richtext className="py-8 px-grid" text={item.text} />
              </div>
            </div>
          ))}
      </div>
    </BlockWrapper>
  )
}

export default FaqBlock
